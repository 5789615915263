export default class ResaIngenie {

	constructor() {

		if ($('#widget-fiche-ingenie-dispo').length) {
			ResaIngenie.widgetDispo();
		}

		if ($('#widget-fiche-ingenie-resa').length) {
			ResaIngenie.widgetResa();
		}
	}

	static widgetDispo() {
		let dispo = {};

		if (Theme.sit.type_flux.meubles) {
			dispo = {
				options: {
					templates: {
						Days: 'semaineLigne' // Vue semaine utiliser pour les meublés sinon retirer
					},
				}
			}
		}

		// Crée un widget dispo
		const widget_dispo_resa = new IngenieWidgetDispoResa.Client({
			url: Theme.url_reservation,
			code: Theme.sit.identifiant,
			elementHtml: 'widget-fiche-ingenie-dispo',
			enable_dispo: true, // true/false (active/désactive dispo) – utiliser « false » selon les besoins
			enable_resa: false, // true/false (active/désactive resa) – toujours actif
			styles: {
				direction: 'horizontal',
				responsiveAt: '768',
				inlineLabels: true,
				// variables: {
				//     primaryColor: '#273483',
				//     primaryTextColor: '#ffffff',
				//     secondaryColor: '#ffffff',
				//     tertiaryColor: '#e1e1e1',
				//     buttonsColor: '#dd8843',
				//     borderRadius: '3px',
				//     shadowColor: 'transparent',
				//     innerShadowColor: 'transparent',
				// }
			},
			dispo: dispo,
			resa: {
				typeWidget: 'OBJET'
			},
		});
		widget_dispo_resa.init();
	}

	static widgetResa() {
		let dispo = {},
			enable_dispo = false,
			enable_resa = false;

		if (Theme.sit.type_flux.meubles) {
			dispo = {
				options: {
					templates: {
						Days: 'semaineLigne'
					},
				}
			}
		}

		if (Theme.type_post == 'activite' || Theme.type_post == 'fetemanifestation' || Theme.type_post == 'equipement') {
			enable_dispo = false;
			enable_resa = true;
		} else {
			enable_dispo = true;
			enable_resa = true;
		}

		console.log(Theme.type_post);
		// Crée un widget resa
		const widget_dispo_resa = new IngenieWidgetDispoResa.Client({
			url: Theme.url_reservation,
			code: Theme.sit.identifiant,
			elementHtml: 'widget-fiche-ingenie-resa',
			enable_dispo: enable_dispo,
			enable_resa: enable_resa,
			styles: {
				direction: 'horizontal',
				responsiveAt: '768',
				inlineLabels: true,
			},
			dispo: dispo,
			resa: {
				typeWidget: 'OBJET'
			},
		});
		widget_dispo_resa.init();
	}
}
