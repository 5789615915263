import Swiper from 'swiper';
import { add_swiper_class, swiper_destroy } from '@arechesbeaufort/js/tools/swiper';
import 'jquery-match-height/dist/jquery.matchHeight-min';
import Utils from '@arechesbeaufort/js/services/utils';

export default class WpetList {
    
    constructor()
    {
        WpetList.view_mode();
        WpetList.slider();
        WpetList.match_height();
        WpetList.filters();
        WpetList.link_offer();
        WpetList.offer_meta_mobile();
        WpetList.bind_wpet_datepicker();
        WpetList.no_booking_offers();
        WpetList.kml_list();
        WpetList.initialize_trigger();
    }
    
    static view_mode()
    {
        const trigger  = '[data-trigger="wpet-mode"]',
              $element = $( '[data-id="wpet-list"]' );
        
        if ( trigger.length > 0 ) {
            $( document ).on( 'click', trigger, function ( e ) {
                e.preventDefault();
                
                const mode = $( this ).attr( 'data-wpet-switch-mode' );
                $element.attr( 'data-wpet-current-mode', mode );
                window.wpet_map_object.mapObject.resize();
                
                $( document ).trigger( 'wpet_change_mode' );
            } );
        }
    }
    
    static number_results( data )
    {
        const $element = $( '[data-id="wpet-list-total-offer"]' );
        $element.text( data.total_global );
    }
    
    static number_current_engine () {
        const filters_total = $( '[data-filters-total]' ).data( 'filters-total' ),
              $element      = $( '[data-id="wpet-list-total-current-engine"]' );
        
        if ( filters_total > 0 ) {
            $element.text( '(' + filters_total + ')' );
        } else {
            $element.text( '' );
        }
    }
    
    static slider()
    {
        const slider         = '[data-id="wpet-offer-slider"]',
            slider_wrapper = '[data-id="wpet-offer-slider-wrapper"]',
            slider_item    = '[data-id="wpet-offer-slider-item"]';

        let swiper_offer;

        if ( $( slider ).length > 0 ) {

            $( slider ).each( function () {

                let slider_elements = $( this ).find( slider_item );

                if ( $( slider_elements ).length > 1 ) {

                    add_swiper_class( $( slider ), $( slider_wrapper ), $( slider_item ) );

                    swiper_offer = new Swiper( $( this ), {
                        mousewheel: {
                            forceToAxis: true,
                            preventClicks: false,
                        },
                        navigation: {
                            nextEl: '[data-trigger="swiper-next"]',
                            prevEl: '[data-trigger="swiper-prev"]',
                        },
                    } );
                }
            } );
        }
    }
    
    static match_height()
    {
        const $element = $( '[data-mh]' );
        
        if ( $element.length > 0 ) {
            $element.matchHeight();
        }
    }
    
    static filters()
    {
        let trigger  = '[data-trigger="wpet-list-filters-button"]',
            close    = '[data-trigger="wpet-list-filters-close"]',
            $element = $( 'body' );
        
        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            $element.addClass( 'isWpetFiltersOpen' );
        } );
        
        $( document ).on( 'click', close, function ( e ) {
            e.preventDefault();
            $element.removeClass( 'isWpetFiltersOpen' );
        } );
        
        $( document ).on( 'wpet_ajax_build', function () {
            $element.removeClass( 'isWpetFiltersOpen' );
        } );
    }
    
    static link_offer()
    {
        const trigger = '[data-trigger="wpet-click"]';
        
        if ( trigger.length > 0 ) {
            $( document ).on( 'click', trigger, ( e ) => {
                if ( !$( e.target ).hasClass( 'slider-arrow' ) ) {
                    window.location = $( e.currentTarget ).parents( '[data-id="wpet-offer"]' ).find( '[data-id="wpet-offer-link"]' ).attr( 'href' );
                }
            } );
        }
    }
    
    static offer_meta_mobile()
    {
        const trigger = '[data-trigger="wpet-offer-mobile-button"]',
              element = '[data-id="wpet-offer-mobile-list"]';
        
        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            $( this ).parents( '[data-id="wpet-offer"]' ).find( element ).toggle();
        } );
    }
    
    static form_select()
    {
        $( 'select' ).parent().not( '.formSelect' ).children( 'select' ).wrap( '<div class="formSelect"></div>' );
    }
    
    static back_to_top()
    {
        $( 'html, body' ).animate( {
                                       scrollTop: $( '[data-id="wpet-list"]' ).offset().top
                                   }, 300 );
    }

    static bind_wpet_datepicker() {

        if ( typeof Picker === 'function' ) {

            $('.wpet_datepicker, .marketplace_startdate .picker__input').each( function(index, el) {

                var $element = $(this);

                setTimeout( function() {

                    var $wpet_datepicker = $element.pickadate('picker');

                    if ( $wpet_datepicker !== undefined ) {

                        $wpet_datepicker.on('open', function(e) {
                            $('body').addClass('isWpetDatepickerActive');
                        });

                        $wpet_datepicker.on('close', function(e) {
                            $('body').removeClass('isWpetDatepickerActive');
                        });
                    }

                }, 0);
            });
        }
    }

    static no_booking_offers()
    {
        let no_offer_booking_online = false;
        $( '[data-id="wpet-no-booking-message"]' ).remove();
        $.each( $( '[data-id="wpet-offer"]' ), function ( i, e ) {
            if ( (0 === $( this ).find( '.wpetOfferThumbnailBooking.available' ).length) && (0 === $( '[data-id="wpet-no-booking-message"]' ).length) ) {
                if ( 0 === i ) {
                    no_offer_booking_online = true;
                }
                if ( no_offer_booking_online == false ) {
                    $( '<p data-id="wpet-no-booking-message" class="wpetListNoBookingMessage">' + Theme.translate.wpet_no_booking + '</p>' ).insertBefore( $( this ) );
                    return false;
                }
            }
        } );
    }

    static marketplace_tooltip_enable()
    {
        if ( $( '[data-wpet-search="martetplace"]' ).length > 0 ) {
            if ( typeof window.IRIS.wpet_marketplace !== 'undefined' ) {
                window.IRIS.wpet_marketplace.tooltip_enable = true;
            }
        }
    }

    static kml_list()
    {
        let current_gpx = null;
        
        const trigger = '[data-trigger="wpet-show-trace"]';
        
        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            
            if ( $( '.wpetList[data-wpet-current-mode="mixte"]' ).length > 0 ) {
                
                if ( current_gpx !== null ) {
                    window.wpet_map_object.mapObject.resetKmlGpx( current_gpx );
                }
                
                let gpx_url = $( this ).data( 'gpx' );
                
                if ( gpx_url != '' && gpx_url != undefined && gpx_url != null ) {
                    
                    //url kml
                    let url = gpx_url;
                    
                    current_gpx = gpx_url;
                    
                    //est ce que l'on recentre la carte avec le kml ou non
                    let recenter = true;
                    
                    //style du kml
                    let style = {
                        strokeColor: '#f75542',
                        weight: 3
                    };
                    
                    window.wpet_map_object.mapObject.bounds = new window.wpet_map_object.mapAdapter.Bounds();
                    window.wpet_map_object.mapObject.addKmlGpx( url, style, recenter );
                    
                } else {
                    window.wpet_map_object.mapObject.fitBounds( wpet_map_object.bounds );
                }
                
            }
        } );
    }

    static initialize_trigger()
    {
        $( document ).on( 'wpet_ajax_build', ( e, data ) => {
            WpetList.form_select();
            WpetList.slider();
            Utils.lazy();
            Utils.bootstrap();
            WpetList.match_height();
            WpetList.back_to_top();
            WpetList.number_results( data );
            WpetList.number_current_engine();
            WpetList.no_booking_offers();
        } );
    
        $( document ).on( 'wpet_ajax_build wpet_ajax_only_form', () => {
            WpetList.form_select();
            WpetList.bind_wpet_datepicker();
        });

        //$( document ).on( 'wpet_ajax_navigation', () => {
        //    WpetList.slider();
        //    WpetList.match_height();
        //} );

        $( document ).on( 'wpet_change_mode', () => {
            swiper_destroy( '[data-id="wpet-offer-slider"]' );
            WpetList.slider();
            WpetList.match_height();
            WpetList.back_to_top();
        } );

        $( document ).on( 'wpet_map_ready', () => {
            WpetList.marketplace_tooltip_enable();
        } );
    }
};

