import Swiper from 'swiper';
import { add_swiper_class } from '@arechesbeaufort/js/tools/swiper';
import 'jquery-match-height/dist/jquery.matchHeight-min';
import 'bootstrap/js/src/modal';
import {LatLng} from "../../../../../../plugins/wp-etourisme/public/libs/js/leaflet/leaflet";

export default class WpetSingle {

    static #steps = [];
    static #poi   = [];
    static #current_steps;

    static #isStayTemplate = $('.single-sejour').length > 0;

    static #isMapActive = $('.map').length > 0;

    static #steps_bounds;


    constructor()
    {
        WpetSingle.mobile_map();
        WpetSingle.toggle_title();
        WpetSingle.slider_gallery();
        WpetSingle.slider_table();
        WpetSingle.slider_related_offers();
        WpetSingle.anchor_navigation();
        WpetSingle.steps_map();
        WpetSingle.carousel_steps();
        WpetSingle.active_marker();
        WpetSingle.toggle_mode_steps();
        WpetSingle.poi_map();
        WpetSingle.interest_points_slider();
        WpetSingle.interest_points_slider_marker();
        WpetSingle.fullscreen_map();
        WpetSingle.active_poi_category();
        WpetSingle.step_detail_mobile();
        WpetSingle.switch();
        WpetSingle.resa_auto_open();
        WpetSingle.init_stay();
    }
    
    static mobile_map()
    {
        let $trigger = $( '[data-trigger="wpet-show-map"]' ),
            $close   = $( '[data-trigger="wpet-close-map"]' ),
            $element = $( '[data-id="wpet-mobile-map"]' );
        
        $trigger.on( 'click', function ( e ) {
            e.preventDefault();
            $element.show();
            window.wpet_map_object.mapObject.resize();
            $( document ).trigger( 'wpet_map_mobile_show' );
        } );
        
        $close.on( 'click', function ( e ) {
            e.preventDefault();
            $element.hide();
        } );
    }
    
    static toggle_title()
    {
        let $trigger = $( '[data-trigger="wpet-open-strate"]' ),
            element  = '[data-id="wpet-strate"]';
        
        $trigger.each( function () {
            $( this ).on( 'click', function () {
                $( this ).parents( element ).toggleClass( 'open' );
                $( this ).toggleClass( 'open' );
            } );
        } );
        
    }

    static slider_table()
    {
        const slider         = '[data-id="wpet-table-slider"]',
            slider_wrapper = '[data-id="wpet-table-slider-wrapper"]',
            slider_item    = '[data-id="wpet-table-slider-item"]';

        if ( $( slider_item ).length > 1 ) {

            $( slider ).each( function () {

                let slider_elements = $( this ).find( slider_item );

                if ( $( slider_elements ).length > 1 ) {

                    add_swiper_class( $( this ), $( this ).find( slider_wrapper ), $( this ).find( slider_item ) );

                    let table = new Swiper( $( this ), {
                        mousewheel: {
                            forceToAxis: true,
                        },
                        keyboard: true,
                        spaceBetween: 20,
                        speed: 600,
                        pagination: {
                            el: '[data-trigger="swiper-dots"]',
                            clickable: true,
                        },
                    } );
                }
            } );
        }

    }

    static slider_gallery()
    {
        const slider         = '[data-id="wpet-gallery-slider"]',
            slider_wrapper = '[data-id="wpet-gallery-slider-wrapper"]',
            slider_item    = '[data-id="wpet-gallery-slider-item"]';

        let swiper_gallery;

        if ( $( slider ).length > 0 ) {

            $( slider ).each( function () {

                let slider_elements = $( this ).find( slider_item );

                if ( $( slider_elements ).length > 1 ) {

                    add_swiper_class( $( slider ), $( slider_wrapper ), $( slider_item ) );

                    swiper_gallery = new Swiper( $( this ), {
                        mousewheel: {
                            forceToAxis: true,
                            preventClicks: false,
                        },
                        navigation: {
                            nextEl: '[data-trigger="swiper-next"]',
                            prevEl: '[data-trigger="swiper-prev"]',
                        },
                    } );
                }
            } );
        }
    }

    static slider_related_offers()
    {

        const slider         = '[data-id="wpet-related-offers-slider"]',
            slider_wrapper   = '[data-id="wpet-related-offers-slider-wrapper"]',
            slider_item      = '[data-id="wpet-offer"]';

        if ( $( slider_item ).length > 0 ) {

            $( slider ).each( function () {

                add_swiper_class( $( this ), $( this ).find( slider_wrapper ), $( this ).find( slider_item ) );

                new Swiper( $( this ), {
                    slidesPerView: 3.15,
                    mousewheel: {
                        forceToAxis: true,
                    },
                    keyboard: true,
                    spaceBetween: 20,
                    speed: 600,
                    pagination: {
                        el: '[data-trigger="swiper-dots"]',
                        clickable: true,
                    },
                    breakpoints: {
                        0: {
                            slidesPerView: 1.15,
                            spaceBetween: 20
                        },
                        651: {
                            slidesPerView: 2.15,
                            spaceBetween: 20
                        },
                        1001: {
                            slidesPerView: 3.15,
                            spaceBetween: 40
                        }
                    }
                } );
            } );
        }

    }


    static anchor_navigation()
    {
        let $anchor      = $( '[data-id="wpet-anchor"]' ),
            $anchor_item = $( '[data-trigger="wpet-anchor-item"]' ),
            $element     = $( '[data-id="wpet-strate"]' );
        
        if ( $anchor.length > 0 ) {
            let shift = 0;
            if ( window.matchMedia( "(max-width: 650px)" ).matches ) {
                shift = 50;
            }
            WpetSingle.get_id_active( $element, $anchor, $anchor_item, shift );
        }
    }
    
    static get_id_active( parts_item, wrapper_nav, nav_item, shift )
    {
        shift = (typeof shift !== 'undefined') ? shift : 0;
        
        let item_offset = {};
        let id_active   = '';
        
        $.each( parts_item, function () {
            
            item_offset[ $( this ).attr( 'id' ) ] = $( this ).offset().top;
        } );
        
        $( window ).scroll( function () {
            
            for ( let id in item_offset ) {
                
                if ( item_offset[ id ] < (nav_item.find( 'a[href="#' + id + '"]' ).offset().top + shift) ) {
                    
                    id_active = id;
                }
            }
            
            if ( '' != id_active ) {
                if ( item_offset[ Object.keys( item_offset )[ 0 ] ] > (nav_item.find( 'a[href="#' + id_active + '"]' ).offset().top + shift) ) {
                    
                    nav_item.find( 'a' ).removeClass( 'active' );
                    
                } else {
                    nav_item.find( 'a' ).removeClass( 'active' );
                    nav_item.find( 'a[href="#' + id_active + '"]' ).addClass( 'active' );
                }
            }
            
        } );
    }

    static steps_map()
    {
        const element = "[data-id='wpet-step-item']";
        const staySidebarElement = "[data-id='wpet-steps-sidebar']";

        if ( $( element ).length > 0 && WpetSingle.#isMapActive ) {
            
            $( window ).bind( 'wpet_map_results', function () {
                let bounds = new window.wpet_map_object.mapAdapter.Bounds();
                
                if ( $( element ).length > 0 ) {
                    const $markers = $( element );
                    
                    // Check if localisation data is in array
                    let has_element = false;
                    $.each( $markers, function ( index, data ) {
                        if ( ($( this ).data( 'lat' ) !== 0) && ($( this ).data( 'lng' ) !== 0) ) {
                            has_element = true;
                            return false;
                        }
                    } );
                    
                    $.each( $markers, function ( index, data ) {
                        index++;
                        if ( ($( this ).data( 'lat' ) !== 0) && ($( this ).data( 'lng' ) !== 0) ) {
                            
                            const latLng      = new window.wpet_map_object.mapAdapter.LatLng( $( this ).data( 'lat' ), $( this ).data( 'lng' ) );
                            const marker_data = Theme.theme_url + "/assets/dist/images/markers/number/marker_" + index + ".svg";
                            
                            let markerOpt = {
                                title: index,
                                visible: true,
                                id: index,
                                is_reference: false,
                                oldicon: wpetMaps.options.marker,
                                iconSize: [
                                    30,
                                    30
                                ],
                            };

                            const marker = new window.wpet_map_object.mapAdapter.Marker( latLng, window.wpet_map_object.map, marker_data, markerOpt );
                            WpetSingle.#steps.push( marker );
                            bounds.extend( latLng );

                        } else {
                            
                            const latLng = new window.wpet_map_object.mapAdapter.LatLng( 0, 0 );
                            const marker = new window.wpet_map_object.mapAdapter.Marker( latLng, window.wpet_map_object.map );
                            WpetSingle.#steps.push( marker );

                        }
                    } );

                    // if location data, we remove the initial marker
                    if ( has_element == true ) {
                        window.wpet_map_object.markers[ 0 ].reset( window.wpet_map_object.mapObject.map );
                        window.wpet_map_object.mapObject.fitBounds( bounds );
                        WpetSingle.#steps_bounds = bounds;
                    }

                    if ( window.matchMedia( "(min-width: 1000px)" ).matches || WpetSingle.#isStayTemplate) {
                        $( window ).on( 'scroll', function () {
                            const scrollTop = $( this ).scrollTop();
                            $( element ).each( function ( index, value ) {
                                const index_step = $( this ).attr( 'data-wpet-step-item' ),
                                      top        = $( this ).offset().top,
                                      height     = $( this ).outerHeight();

                                let offset = WpetSingle.#isStayTemplate ? 100 : 0 ;

                                if(window.matchMedia( "(max-width: 1000px)" ).matches){
                                    offset = $(window).height() - 100;
                                }

                                if ( (scrollTop >= top - offset && scrollTop <= top + height - offset) ) {
                                    WpetSingle.active_marker( index_step );
                                    WpetSingle.#current_steps = index_step;
                                    if(WpetSingle.#isStayTemplate){
                                        $(staySidebarElement).find('[data-value="' + index_step + '"]').addClass('active');
                                    }
                                } else {
                                    WpetSingle.classic_marker( index_step );
                                    if(WpetSingle.#isStayTemplate){
                                        $(staySidebarElement).find('[data-value="' + index_step + '"]').removeClass('active');
                                    }
                                }
                            } );
                        } );
                        
                        //Hover step
                        $( element ).hover(
                            function () {
                                let index_step = $( this ).attr( 'data-wpet-step-item' );
                                WpetSingle.active_marker( index_step );
                            },
                            function () {
                                let index_step = $( this ).attr( 'data-wpet-step-item' );
                                if ( WpetSingle.#current_steps != index_step ) {
                                    WpetSingle.classic_marker( index_step );
                                }
                            }
                        );
                        
                        WpetSingle.anchor_marker();
                    }
                }
                
            } );
        }
    }

    static active_marker( index_step = null )
    {
        $.each( WpetSingle.#steps, function ( index, value ) {

            if ( value.marker.id == index_step && index_step !== WpetSingle.#current_steps ) {
                WpetSingle.#current_steps = index_step;
                value.marker.setZIndexOffset( 10000 );
                value.setIcon( Theme.theme_url + '/assets/dist/images/markers/number/hover-marker_' + index_step + '.svg' );

                if(WpetSingle.#isStayTemplate){
                    WpetSingle.active_step_pois(index_step);
                }
            }
        } );
    }

    static active_step_pois( index_step )
    {
        WpetSingle.#poi = [];

        let $poiElmt = "[data-poi-step='" + index_step + "']";
        let bounds = new window.wpet_map_object.mapAdapter.Bounds();

        $.each(WpetSingle.#steps_bounds.bounds, function(i, data){
            const latLng      = new window.wpet_map_object.mapAdapter.LatLng( data.lat, data.lng );
            bounds.extend(latLng);
        })




        if ( $( $poiElmt ).length > 0 ) {
            $.each( $($poiElmt), function ( index, data ) {
                if ( ($( this ).data( 'lat' ) !== 0) && ($( this ).data( 'lng' ) !== 0) ) {
                    const latLng      = new window.wpet_map_object.mapAdapter.LatLng( $( this ).data( 'lat' ), $( this ).data( 'lng' ) );
                    const marker_data = Theme.theme_url + "/assets/dist/images/markers/marker-poi.svg";
                    let markerOpt     = {
                        title: index,
                        visible: true,
                        id: index,
                        is_reference: false,
                        oldicon: wpetMaps.options.marker,
                        iconSize: [
                            30,
                            30
                        ],
                    };

                    const markerPoi  = new window.wpet_map_object.mapAdapter.Marker( latLng, window.wpet_map_object.map, marker_data, markerOpt );
                    let permalink = $( this ).attr( 'href' );
                    let title     = $( this ).html();
                    markerPoi.setInfoWindow( '<div class="infoBox"><div class="infoboxContent"><p class="infoboxContentTitle">' + title + '</p></div></div>' );
                    WpetSingle.#poi.push( markerPoi );
                    bounds.extend( latLng );
                }
            } );
        }

        window.wpet_map_object.mapObject.fitBounds( bounds );
    }

    static classic_marker( index_step )
    {
        $.each( WpetSingle.#steps, function ( index, value ) {
            index++;
            if ( value.marker.id == index_step ) {
                value.setIcon( Theme.theme_url + '/assets/dist/images/markers/number/marker_' + index + '.svg' );
            }
        } );
    }

    static all_classic_marker()
    {
        $.each( WpetSingle.#steps, function ( index, value ) {
            index++;
            value.setIcon( Theme.theme_url + '/assets/dist/images/markers/number/marker_' + index + '.svg' );
        } );
    }

    static zoom_marker( index )
    {
        let $element  = $( "[data-wpet-step-item=" + index + "]" );
        let lat       = $element.attr( 'data-lat' );
        let lng       = $element.attr( 'data-lng' );
        let newCenter = new window.wpet_map_object.mapAdapter.LatLng( lat, lng );
        window.wpet_map_object.mapObject.setCenter( newCenter );
    }

    static anchor_marker()
    {
        $( document ).on( 'click', '.wpet-marker', function ( e ) {
            e.preventDefault();
            let marker_index = $( this ).attr( 'src' );
            marker_index     = '#' + marker_index.split( 'number/' ).pop().split( '.svg' )[ 0 ];
            let offset = 80;
            if(WpetSingle.#isStayTemplate && window.matchMedia( "(max-width: 1000px)" ).matches ){
                offset = 500;
            }
            $( 'html, body' ).animate( {
                scrollTop: $( marker_index ).offset().top - offset
            }, 1000 );
        } );
    }

    static carousel_steps()
    {
        const slider         = '[data-id="wpet-step-carousel"]',
              slider_wrapper = '[data-id="wpet-step-carousel-wrapper"]',
              slider_item    = '[data-id="wpet-step-carousel-item"]';
        
        let swiper_offer;
        if ( $( slider ).length > 0 ) {
            
            $( slider ).each( function () {
                
                let slider_elements = $( this ).find( slider_item );
                
                if ( $( slider_elements ).length > 1 ) {
                    
                    add_swiper_class( $( this ), $( this ).find( slider_wrapper ), $( this ).find( slider_item ) );
                    
                    swiper_offer = new Swiper( $( this ), {
                        mousewheel: {
                            forceToAxis: true,
                        },
                        spaceBetween: 20,
                        slidesPerView: 1,
                        breakpoints: {
                            650: {
                                slidesPerView: 2,
                                centeredSlides: true,
                            }
                        },
                        on: {
                            slideChangeTransitionEnd: function () {
                                let index = swiper_offer.activeIndex;
                                WpetSingle.all_classic_marker();
                                WpetSingle.zoom_marker( index );
                                WpetSingle.active_marker( index );
                            }
                        }
                    } );
                    
                    $( document ).on( 'click', '.wpet-marker', function ( e ) {
                        e.preventDefault();
                        let marker_index = $( this ).attr( 'src' );
                        marker_index     = marker_index.split( 'number/marker_' ).pop().split( '.svg' )[ 0 ];
                        swiper_offer.slideTo( marker_index );
                    } );
                }
            } );
            
        }
    }

    static toggle_mode_steps()
    {
        const $strate_map  = $( '.wpetStepMap' );
        const $strate_list = $( '.wpetStepList' );
        
        $( document ).bind( 'wpiris-switch-click', function ( e, opts ) {
            let value = opts.value;
            if ( value == 0 ) {
                $strate_map.removeClass( 'hide' );
                $strate_list.addClass( 'hide' );
            } else {
                $strate_map.addClass( 'hide' );
                $strate_list.removeClass( 'hide' );
            }
        } );
    }

    static step_detail_mobile()
    {
        const element       = '[data-wpet-step-item]',
              trigger       = '[data-trigger="wpet-step-modal-open"]',
              modal         = '[data-id="wpet-step-modal"]',
              modal_content = '[data-id="wpet-step-modal-content"]',
              close         = '[data-trigger="wpet-step-modal-close"]';
        
        if ( $( element ).length > 0 ) {
            
            $( document ).on( 'click', trigger, function ( e ) {
                e.preventDefault;
                let index   = $( this ).parents( element ).attr( 'data-wpet-step-item' );
                let content = $( '[data-id="steps-list"] [data-wpet-step-item=' + index + ']' ).html();
                $( modal_content ).html( '' );
                $( modal_content ).append( content );
                $( modal ).addClass( 'open' );
            } );
            
            $( document ).on( 'click', close, function ( e ) {
                e.preventDefault;
                $( modal ).removeClass( 'open' );
            } );
        }
    }
    
    static poi_map()
    {
        const trigger       = '[data-trigger="wpet-poi-tab"]',
              trigger_close = '[data-id="wpet-poi-item"].open [data-trigger="wpet-poi-tab"]',
              element       = '[data-id="wpet-poi-item"]';
        
        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            $( element ).removeClass( 'open' );
            $( this ).parents( element ).addClass( 'open' );
        } );
        
        $( document ).on( 'click', trigger_close, function ( e ) {
            e.preventDefault();
            $( this ).parents( element ).removeClass( 'open' );
        } );
    }
    
    static interest_points_slider()
    {
        let slider         = '[data-id="wpet-interest"]',
            slider_wrapper = '[data-id="wpet-interest-list"]',
            slider_item    = '[data-id="wpet-interest-item"]:not(.swiper-slide-duplicate)';
        
        if ( $( slider ).length > 0 ) {
            $( slider ).each( function () {
                
                let slider_elements = $( this ).find( slider_item );
                
                if ( $( slider_elements ).length > 2 || ($( slider_elements ).length > 1 && window.matchMedia( "(max-width: 650px)" ).matches) ) {
                    
                    add_swiper_class( $( this ), $( this ).find( slider_wrapper ), $( this ).find( slider_item ) );
                    
                    let swiper_post = new Swiper( $( this ), {
                        mousewheel: {
                            forceToAxis: true,
                        },
                        keyboard: true,
                        spaceBetween: 20,
                        speed: 600,
                        loop: true,
                        lazy: true,
                        slidesPerView: 1,
                        tap: 0,
                        pagination: {
                            el: '[data-trigger="swiper-dots"]',
                            clickable: true,
                        },
                        
                        breakpoints: {
                            650: {
                                slidesPerView: 2,
                            },
                        }
                    } );
                }
            } );
        }
    }
    
    static interest_points_slider_marker()
    {
        const element = "[data-id='wpet-interest-item']";
        
        if ( $( element ).length > 0 ) {
            
            $( window ).bind( 'wpet_map_results', function () {
                
                let bounds = new window.wpet_map_object.mapAdapter.Bounds();
                
                if ( $( element ).length > 0 ) {

                    const $markers = $( element );
                    
                    //// Check if localisation data is in array
                    let has_element = false;
                    $.each( $markers, function ( index, data ) {
                        if ( ($( this ).data( 'lat' ) !== 0) && ($( this ).data( 'lng' ) !== 0) ) {
                            has_element = true;
                            return false;
                        }
                    } );
                    
                    $.each( $markers, function ( index, data ) {
                        index++;
                        
                        if ( ($( this ).data( 'lat' ) !== 0) && ($( this ).data( 'lng' ) !== 0) ) {
                            const latLng      = new window.wpet_map_object.mapAdapter.LatLng( $( this ).data( 'lat' ), $( this ).data( 'lng' ) );
                            const marker_data = Theme.theme_url + "/assets/dist/images/markers/marker-poi.svg";
                            let markerOpt     = {
                                title: index,
                                visible: true,
                                id: index,
                                is_reference: false,
                                oldicon: wpetMaps.options.marker,
                                iconSize: [
                                    30,
                                    30
                                ],
                            };
                            
                            const marker  = new window.wpet_map_object.mapAdapter.Marker( latLng, window.wpet_map_object.map, marker_data, markerOpt );
                            let permalink = $( this ).find( 'a' ).attr( 'href' );
                            let title     = $( this ).find( '.interestItemTitle' ).html();
                            
                            marker.setInfoWindow( '<div class="infoBox"><div class="infoboxContent"><p class="infoboxContentTitle">' + title + '</p></div></div>' );
                            
                            WpetSingle.#poi.push( marker );
                            bounds.extend( latLng );
                            
                        } else {
                            const latLng = new window.wpet_map_object.mapAdapter.LatLng( 0, 0 );
                            const marker = new window.wpet_map_object.mapAdapter.Marker( latLng, window.wpet_map_object.map );
                            WpetSingle.#poi.push( marker );
                        }
                    } );
                    
                    // if location data, we remove the initial marker
                    if ( has_element == true ) {
                        window.wpet_map_object.markers[ 0 ].reset( window.wpet_map_object.mapObject.map );
                        window.wpet_map_object.mapObject.fitBounds( bounds );
                    }
                }
                
            } );
        }
    }
    
    static fullscreen_map()
    {
        const trigger = "[data-trigger='wpet-map-fullscreen']",
              map     = "[data-id='wpet-map-container']";
        
        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            $( 'body' ).toggleClass( 'map-is-fullscreen' );
            window.wpet_map_object.mapObject.resize();
        } );
    }
    
    static active_poi_category()
    {
        
        $( '[data-wpet-poi]' ).on( 'click', function () {
            WpetSingle.check_active_poi_category();
        } );
        
    }
    
    static check_active_poi_category()
    {
        let element = '[data-id="wpet-poi-item"]';
        
        $( element ).each( function ( index ) {
            let current_element = $( this );
            let checked         = false;
            
            $( this ).find( '[data-wpet-poi]' ).each( function ( index ) {
                if ( $( this ).is( ':checked' ) ) {
                    checked = true;
                }
            } );
            
            if ( true === checked ) {
                $( this ).addClass( 'checked' );
            } else {
                $( this ).removeClass( 'checked' );
            }
        } );
    }
    
    static switch()
    {
        const trigger      = '[data-trigger="wpiris-switch"]',
              trigger_mode = '[data-switch-value]';
        let new_value      = 0;
        
        if ( trigger.length > 0 ) {
            $( document ).on( 'click', trigger, function ( e ) {
                e.preventDefault();
                let value = $( this ).attr( 'data-switch-active' );
                $( this ).toggleClass( 'switch--checked' );
                
                if ( 0 == value ) {
                    $( this ).attr( 'data-switch-active', 1 );
                    new_value = 1;
                    
                } else {
                    $( this ).attr( 'data-switch-active', 0 );
                    new_value = 0;
                }
                
                $( document ).trigger( 'wpiris-switch-click', {
                    value: new_value,
                } );
            } );
        }
        
        if ( trigger_mode.length > 0 && trigger.length > 0 ) {
            $( document ).on( 'click', trigger_mode, function ( e ) {
                e.preventDefault();
                let value = $( this ).attr( 'data-switch-value' );
                $( trigger ).attr( 'data-switch-active', value );
                
                if ( 0 == value ) {
                    $( trigger ).removeClass( 'switch--checked' );
                } else {
                    $( trigger ).addClass( 'switch--checked' );
                }
                
                $( document ).trigger( 'wpiris-switch-click', {
                    value: value,
                } );
            } );
        }
    }

    static resa_auto_open() {
        const trigger = '[data-element="wpiris-resa-scroll"]',
              strate_resa = $('#reservation');

        if (strate_resa.length > 0) {

            $(trigger).on('click', function () {
                $(strate_resa).addClass('open');
            })
        }
    }

    static init_stay() {
        if(WpetSingle.#isStayTemplate && WpetSingle.#isMapActive){
            $(document).trigger('wpet_map_results');
        }
    }
}
