import '@arechesbeaufort/scss/app-wpet-list-hide.scss';
import '@arechesbeaufort/scss/app-wpet-list-aside.scss';
import '@arechesbeaufort/scss/app-wpet-single.scss';
import '@arechesbeaufort/scss/app-wpet-single-itinerary.scss';
import '@arechesbeaufort/scss/app-wpet-single-stay.scss';
import WpetList from '@arechesbeaufort/js/wpet/list-hide';
import WpetSingle from '@arechesbeaufort/js/wpet/single';
import WpetInteractiveMap from '@arechesbeaufort/js/wpet/interactive-map';
import ResaIngenie from '@arechesbeaufort/js/services/resa-ingenie';

document.addEventListener( "DOMContentLoaded", function () {
    new WpetList();
    new WpetSingle();
    new WpetInteractiveMap();
    new ResaIngenie();
});
