export default class WpetInteractiveMap {

	constructor() {
		WpetInteractiveMap.filter();
		WpetInteractiveMap.center_user();
	}

	static filter()
	{
		const trigger   = '[data-trigger="interactive-map-filter-open"]',
			close     = '[data-trigger="interactive-map-filter-close"]',
			$filter   = $('[data-id="interactive-map-filter"]');

		$(document).on('click', trigger, function (e) {
			e.preventDefault();
			$filter.css('right', '0%');
		});

		$(document).on('click', close, function (e) {
			e.preventDefault();
			$filter.css('right', '-100%');
		});
	}

	static center_user()
	{
		const trigger = '[data-trigger="wpiris-center-user"]';

		$(document).on('click', trigger, function (e) {
			e.preventDefault();
			WpetInteractiveMap.position();
		});
	}

	static position()
	{
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(WpetInteractiveMap.position_success, null, {
				enableHighAccuracy: true,
				timeout: 5000,
				maximumAge: 10
			});
		}
	}

	static position_success(position)
	{
		if (typeof position.coords != 'undefined') {
			if (typeof position.coords.latitude != 'undefined') {

				// Center
				const new_center = new window.wpet_map_object.mapAdapter.LatLng(position.coords.latitude, position.coords.longitude);

				// Marker
				const icon = Theme.theme_url +'/assets/dist/images/markers/marker.svg';
				const marker = new window.wpet_map_object.mapAdapter.Marker(new_center, window.wpet_map_object.map, icon);

				//Infobulle
				//var htlmContent = '<p>Office de Tourisme</p>'
				//marker.setInfoWindow(htlmContent);

				window.wpet_map_object.mapObject.setCenter(new_center);
				window.wpet_map_object.customMarkers.push(marker);
			}
		}
	}
}

